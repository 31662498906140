<template>
    <div id= "tagmanagepage">
    <div class="backpage ">
      <a href="/taskmanager" class="backbutton">{{symbol }}任务面板</a>  
    </div>
    <div id="goalmanage">
    <div id="tagshow">
    <h2>你的标签
      <button id="addgoalbutton" @click="showAddTagzone_func" v-show="showaddbutton"> <a-tooltip title="创建新的标签" color="red" mouseEnterDelay="1">
      +</a-tooltip> </button>
      <manualicon v-show="!showaddbutton" title="非会员用户,仅支持特定数量的定制标签"/>
      </h2>
    
    <div class="tagzone"> 
      <ul v-for="item in items" class="singletag"  :key="item.id" @click="showeditTagzone_func(item)">
        <div > {{item.Name}}</div>
    </ul>
    </div>
    
    
    </div>
    <div class="tageditzone">
      
    <div  v-show="showAddTagzone" class="tagmanage">
      <div class="closebutton" @click="closebox"><close class="closesvg"/></div>
      <h2>添加标签</h2>
      
      <div class="tip">输入你的标签名</div>
      
         <input placeholder="请输入标签名" v-model="tagcontent" >
      
      <div class="tip">选择该标签的权重</div>
      
        <a-select
          ref="select"
          v-model:value="selectedpriority"
          style="width: 120px"
          :options="options1"
          @focus="focus"
          @change="handleChange"
        ></a-select>
        <button @click="createtag">提交你的标签</button>


  
    </div>
    
    <div id="edittag" v-show="showeditTagzone" class="tagmanage" >
      
      <div class="closebutton" @click="closebox"><close class="closesvg"/></div>
      <h2>编辑标签</h2>
       
        
        <div class="tip">标签名</div>
         <input placeholder="请输入你的标签名" v-model="tagcontent" >
      


    
    <div class="tip">修改你的标签权重</div>
      <a-select
      ref="select"
      v-model:value="selectedpriority"
      style="width: 120px"
      :options="options1"
      @focus="focus"
      @change="handleChange"
    ></a-select>

 

     
      <button @click="modifyTag">修改标签</button>
    
    </div>
    </div>
    <!-- <div id="editgoal">
    
    </div> -->
    </div>
    
    </div>
    </template>
    
    <script>
    import axios from 'axios'
    import close from '../svg/close.vue'
    import configure_options from '../configure'
    import  manualicon from "../svg/manualicon.vue"
    import { defineComponent,ref } from 'vue';
    // import { getCurrentInstance } from 'vue'
    // const axios = require('axios').default;
    export default defineComponent({
      name: 'goalshow',
      props: {
        msg: String,
        
      },
      components:{
 manualicon,
  close},
      // data() {
      //     return {
      //      items:[],
        
      //     }
    
      // },
      setup(props,context) {
        // const { proxy } = getCurrentInstance()
      // const value1 = ref(500);
        const handleChange = value => {
          console.log(`selected ${value}`);
          context.emit("testchild")
        };
        var currentTag=null;
    
        var  options1 =  [{
                            id:0,
                            value:1,  
                          },{
                            id:1,
                            value:2
                          },{
                            id:2,
                            value:3
                          },
                          {
                            id:3,
                            value:4
                          },
                          {
                            id:4,
                            value:5
                          },
                          
                          ]
    
    
    
          var  options2 =  [{
                            id:0,
                            value:"已经放弃",  
                          },{
                            id:1,
                            value:"已经完成"
                          },{
                            id:2,
                            value:"正在进行中"
                          },
                          
                          ]
    
    
            function createtag(){
                  var env =  configure_options["env"]
                
                  console.log(configure_options["app_setting"][env])
                  var serverhost =configure_options["app_setting"][env]["serverhost"]
    
              // alert("穆阿标点击")
              // alert(this.selectedpriority)
            
                if(this.tagcontent==""){
                  alert("目标不能为空")
                  return
                }
    
                var tag  ={"tagname":this.tagcontent,"priority":this.selectedpriority}
                axios.post(serverhost + '/v1/createtag',tag)
                 .then(response => {
                  console.log(response.data)
                  //可以将状态传入到父亲组件
                //    document.getElementById("addtaskinprojectzone").style.display="none"
                //  document.getElementById("goaltreedatapart").style.opacity=1
                //  document.getElementById("goaltreedatapart").style.pointerEvents="auto"
                //  document.getElementById("top").style.opacity=1
                //    emitter.emit("refresh_goaltree","createtaskinproject_component_ask")
                // 要让左侧进行对应的刷新
                this.showAddTagzone = false
                this.getTags()
    
    
    
              })
              .catch(error=>{
                console.log(error)
            
              if(error.response.status==902){
                  alert("不能使用相同的目标名")
              }else{
                alert("服务器出错")
              }})
                return 
            }
    
    
            function modifyTag(){
              // alert("检查海曙是否调用")
              if(this.currentTag==null){
                alert("服务异常,请联系管理员")
                return
              }
              this.showeditgoalzone = false
              var env =  configure_options["env"]
              console.log("目前的目标是这样的")
              console.log(this.currentTag)
                console.log(configure_options["app_setting"][env])
                var serverhost =configure_options["app_setting"][env]["serverhost"]
                var _this = this
                var tag  ={"tagid":this.currentTag.Tagid,tagname:this.tagcontent,priority:this.selectedpriority}
                axios.post(serverhost + '/v1/updatetag',tag)
                 .then(response => {
                  // alert("检查为什么没有触发")
                  console.log(response.data)
                
                  console.log("检查所有的数据消息")
               _this.getTags()
                
                
                })



             
    
            }
    


     function closebox(){
// this.showboxcontainer = false
    this.showAddTagzone = false;
    this.showeditTagzone= false;
    // alert("1")
    // document.getElementById("goalshow").style.width="100vw";
    // document.getElementById("goalshow").style.maxWidth="100vw"
  }


          function showAddTagzone_func(){
            this.showAddTagzone = true
            this.showeditTagzone= false;
            this.currentTag = null;
            this.tagcontent=""
            this.selectedpriority=0
          }
    //先添加后编辑会同时出现
          function showedittagzone_func(item){
            var comments =false
            if(comments){
              return

            }
         
            console.log(item)
            this.currentTag = item
            this.showAddTagzone = false
            this.showeditTagzone = true
           
            this.selectedpriority = item.Priority
            this.tagcontent = item.Name
            //  this.selectedstatus = goal.goalstatus
   
    
    
          }
    
    

      function getTags(){
        var _this = this
               var env =  configure_options["env"]
           
            console.log(configure_options["app_setting"][env])
            var serverhost =configure_options["app_setting"][env]["serverhost"]
    
        axios.get(serverhost+"/v1/gettags").then(
         response=>{
           var data = response.data
               console.log("~~~~~~~~~~~~~~~我正在打印所有未完成的任务~~~~~~~~~")
          console.log(data);
          _this.items = data.tags
          _this.userlevel = data.userlevel
          if(_this.userlevel=="low"&& _this.items.length>20){
             _this.showaddbutton = false;
          }

          // console.log(status);
          // 提取所有的Goal = [{goal1的所有task},{goal2的所有task},{}]
         }
       ).catch(error=>{console.log(error)})
      
        
}


        return {
          createtag,
          modifyTag,
          getTags,
          symbol:"<",
          userlevel:ref("normal"),
          tagcontent:ref(""),
          showAddTagzone:ref(false),
          showeditTagzone:ref(false),
          showaddbutton:ref(true),
          items:ref([]),
          showAddTagzone_func,
          closebox,
          showeditTagzone_func: showedittagzone_func,
          currentTag,
          handleChange,
          selectedpriority:ref(0),
          selectedstatus:ref("未完成"),
          options1,
          options2
       
        };
      },
      mounted(){
        this.getTags()
      
        
      }
    })
    </script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped lang="scss">
    


 


h2{
      font-size: large;
      color: black;
      font-weight: 700;
      text-align: left;
     }


   #tagmanagepage{

    background-color: rgb(222, 235, 251);
    height:100vh
   }


    #goalmanagepage {
      display: flex;
      flex-direction:column;
    }
    
    .backpage{
      /* position: absolute;; */
      right: 0%;
  width: 200px;
  margin-left: auto;
    
      /* display:block; */
      .backbutton{
     height: 45px;
    line-height: 45px;
    width: 150px;
    display: inline-block;
    vertical-align: top;
    margin: 0 15px;
    border: 1px solid #181828;
    // border-radius: 5%;
    color: #181828;
    text-decoration: none;
    background: snow;
    font-family: "handwriting1";
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: .1rem;
  
    border-radius:5px;
    overflow: hidden;
    text-align: center;

    &:hover{
      background: rgb(184, 115, 115);
      color:white;
    }


  }

    }
    #goal{
        display:block;
    }
    
    #goalmanage{
      display: flex;
      /* justify-content: space-between; */
      margin-right: 100px;
    }
    
    #addgoalbutton{
      border: none;
      background: none;
    }
    
    
    .goalstatus_finished::after{
      color: red;
      content:" 已经完成了"
    }
    
    .goalstatus_giveup::after{
      color:rgb(129, 13, 238);
      content: " 放弃了x";
    }
    
    #tagshow{
      font-family: fangsong;
 
      text-align: left;
      margin-left: 50px;
      margin-right: 200px;
      /* position:absolute; */
      /* left: 40%; */


      .tagzone{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr ; 
    }


  
    .singletag{
      border: 1px solid blue;
      // background: rgb(206, 9, 9);
      background: blue;
      text-align: center;
      color: white;
      margin-left:1px;
      padding:3px;
      border-radius: 10%;
      font-weight: 600;
      // border-radius: 5%;
    }

    }
   



    .tageditzone{
      .tagmanage{

        min-width: 23vw;
  display: flex;
  // justify-content: space-between; 
  margin-right: 100px;
  flex-direction: column;
  // border: #915353 dashed 1px;
  background: rgb(238, 238, 245);
  
  border: #915353 dashed 1px;
  padding:5%;
  border-radius: 5%;

button{
  margin-top:14px ;
}
  .tip{
  padding-top: 14px;
  text-align: left;
}
      }
     

}




.specialzone{
  display: flex;
  flex-direction: column;
}


.closebutton{
  text-align: right;
}

.backpage{
  // margin-left: auto;
  text-align: right;
}
.namezone{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top:5px;
}

    </style>
    